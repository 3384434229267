.landing-page {
  @apply flex flex-col text-[#6B7181] justify-between
  text-[12px]
  tablets:text-[14px]
  regular-desktops:text-[16px];
}

.landing-page hr {
  @apply text-[#E6E8EC];
}

.landing-page .content__company {
  @apply mb-8;
}

.landing-page .detail__title {
  @apply mb-8;
}

.landing-page .detail__slogans {
  @apply mt-8 mb-8;
}

.landing-page .app__header {
  @apply flex;
}

.landing-page .header__content {
  @apply p-4
    tablets:ml-[80px]
    small-desktops:mt-[24px]
    small-desktops:ml-[160px]
    max-w-[552px];
}

.landing-page .header__image {
  @apply items-center
  hidden
  small-desktops:flex;
}

.landing-page .header__image > img {
  @apply max-w-[709px]
  min-w-[500px]
  w-full;
}

.header__content .content__detail {
  @apply font-montserrat font-medium;
}

.landing-page .detail__contact-us {
  @apply flex relative h-[64px];
}

.landing-page .detail__contact-us input {
  @apply p-2 rounded-[100px]
  h-full
  leading-3
  w-full pl-3
  flex flex-row
  justify-between
  align-middle
  bg-[#eaeaea] text-[16px] text-[#6B7181] font-medium
  transition-all
  duration-1000
  border-transparent
  border-[1px];
}

.landing-page .detail__contact-us input:focus {
  @apply outline-none border-[#5C3BDE];
}

.landing-page .detail__contact-us input::placeholder {
  @apply text-[#6B7181] duration-1000 text-[14px] small-desktops:text-[16px];
}

.landing-page .detail__contact-us input:focus::placeholder {
  @apply text-transparent duration-1000;
}

.landing-page .detail__contact-us button {
  @apply bg-[#5C3BDE] text-white
    font-bold
    h-[48px]
    m-[8px]
    px-[16px]
    rounded-[24px];
}

.landing-page .app__footer {
  @apply bottom-0 items-center flex flex-col font-open_sans;
}

.app__footer hr {
  @apply text-[#E6E8EC];
}

.footer__content {
  @apply mb-8 text-[#6B7181] font-open_sans font-semibold
  text-[12px] regular-desktops:text-[16px];
}

.email-underline {
  border-bottom-width: 0;
  background-image: linear-gradient(transparent, transparent), linear-gradient(#fff, #fff);
  background-size: 0 1px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  opacity: 0;
  transition: background-size 1s ease-out, opacity 1s ease-out;
}

.email-underline-animate-line {
  background-image: linear-gradient(transparent, transparent), linear-gradient(#5c3bde, #5c3bde);
}

.email-underline:hover {
  opacity: 1;
  background-size: 100% 1px;
  background-position: 0 100%;
}
