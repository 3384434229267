.login {
  @apply border
    shadow-sm
    rounded-xl
    bg-primary
    flex
    flex-col
    py-4
    w-[600px];
}
