.wrapper {
  @apply font-montserrat
    items-center
    flex
    flex-col
    w-[268px]
    tablets:w-full
    text-center
    h-fit
    tablets:px-6;
}

.wrapper__icon-cover {
  @apply mb-[24px]
    p-[35px]
    bg-[#F4F5F6]
    rounded-[100px];
}

.wrapper__title {
  @apply font-bold
    font-montserrat
    mb-[12px]
    text-[16px]
    small-desktops:text-[20px]
    leading-5
    tablets:text-base;
}

.wrapper:hover {
  @apply cursor-pointer;
}

.wrapper:hover .wrapper__title {
  @apply text-primary;
}

.wrapper__detail {
  @apply font-open_sans
    font-light
    text-left
    text-[14px]
    leading-5
    small-desktops:text-[16px]
    text-[#6B7181]
    tablets:text-sm;
}

.display-text-group {
  @apply text-left;
}

.read-more-button {
  @apply text-primary;
}

.read-more-button:hover {
  @apply cursor-pointer;
}

.popup-overlay {
  @apply pt-[30px]
  small-desktops:pt-0;
}

.popup-content {
  @apply !w-[90%]
  h-[90%]
  tablets:!w-[70%]
  tablets:h-fit
  small-desktops:!w-[50%];
}
.popup-container {
  @apply w-full;
}

.popup-container > .popup-container__button {
  @apply w-full
  flex
  justify-center
  flex-row-reverse;
}

.popup-container__button > button {
  @apply hover:bg-primary
  hover:border-none
  hover:text-white
  transition;
}
