select:required:invalid {
  color: gray;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-repeat: no-repeat;
  background-image: url("../../../../../../public/assets/arrow-down-card.svg");
  background-position-x: 100%;
  background-position-y: 15px;
}

.form-upload-btn {
  @apply border-[2px] 
  border-primary 
  font-bold 
  text-primary
  w-fit
  h-fit
  py-[4px]
  px-[24px]
  rounded-[16px]
  hover:bg-primary 
  hover:bg-opacity-20 
  duration-500;
}

.form-submit-btn {
  @apply text-[#fff]
  w-fit
  flex
  px-[24px]
  py-[12px] 
  rounded-[24px]
  font-bold bg-[#5C3BDE] p-3 hover:bg-opacity-80 duration-500;
}

option[value=""][disabled] {
  display: none;
}
option {
  color: black;
}
