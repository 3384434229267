.job-card {
  @apply flex
    flex-col;
}

.job-card .apply-btn {
  @apply border-[2px] 
  border-primary 
  font-bold 
  text-primary
  text-[14px]
  px-6
  py-1
  tablets:py-[4px]
  tablets:px-[24px]
  rounded-[16px]
  hover:bg-primary 
  hover:bg-opacity-20 
  duration-500;
}
