.role-detail-page {
  @apply flex 
  flex-col
  justify-center
  p-4
  gap-9
  /* tablets:p-6 */
  /* small-desktops:px-9
  regular-desktops:px-[88px]
  large-desktops:px-[240px]; */;
}

.role-detail-page__container {
  @apply w-full;
}

.role-detail-page__container .apply-btn {
  @apply border-[2px] 
  border-primary 
  w-fit
  h-fit
  font-bold 
  text-primary
  py-[4px]
  px-[24px]
  rounded-[16px]

  hover:bg-primary 
  hover:bg-opacity-20 
  duration-500;
}
