.career-banner {
  @apply flex
    flex-col;
}

.career-banner > section {
  @apply flex flex-col 
  w-full gap-2;
}

.career-banner > section > p {
  @apply w-full;
}

.career-selection {
  @apply tablets:w-[268px];
}

.career-banner > section > .career-banner__mobile {
  @apply flex w-full tablets:hidden
  !font-system_ui
  mb-[48px]
  !font-normal;
}
