input:focus {
  @apply outline-0
  border-red-200;
}

label {
  @apply text-[#6B7181] text-[12px];
}

label:focus-within {
  @apply text-black;
}

.styles-module_tooltip__mnnfp {
}
