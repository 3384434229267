.industry-container {
  @apply w-full
      flex
      justify-center;
}

.industry-container > section {
  @apply w-full
      flex
      flex-col
      items-center
      tablets:px-[24px]
      small-desktops:px-[36px]
      large-desktops:px-[0px]
      large-desktops:w-[1440px];
}

.industry-container > section > h2 {
  @apply text-2xl
      text-center
      px-[16px]
      tablets:text-[32px]
      tablets:leading-[48px]
      mb-[16px]
      max-w-[547px]
      small-desktops:text-[40px]
      small-desktops:leading-[54px]
      font-montserrat w-[343px] tablets:w-[478px] small-desktops:w-[547px];
}

.industry-card {
  @apply w-[255px]
      flex
      flex-col
      gap-8
      tablets:w-full;
}

.industry-card__detail {
  @apply w-full
      ml-[16px]
      flex
      mr-[8px]
      gap-4;
}

.detail__section > .section__title {
  @apply font-montserrat
      text-[20px]
      leading-[24px]
      tablets:text-[24px]
      tablets:leading-[32px]
      small-desktops:text-[32px]
      small-desktops:leading-[48px];
}

.detail__section > .section__detail {
  @apply text-[16px]
  h-[245px]
  tablets:text-[16px]
  leading-[20px]
  tablets:leading-7
  font-open_sans
  font-normal;
}

.industry-card__image {
  @apply rounded-[16px]
      bg-[#E6E8EC]
      mb-4
      tablets:mb-0
      w-[85vw]
      h-[85vw]
      tablets:w-[400px]
      tablets:h-[353px];
}

.popup-overlay {
  @apply pt-[30px]
  small-desktops:pt-0;
}

.popup-content {
  @apply !w-[90%]
  h-[90%]
  tablets:!w-[70%]
  tablets:h-fit
  small-desktops:!w-[50%];
}
.popup-container {
  @apply w-full;
}

.popup-container > .popup-container__button {
  @apply w-full
  flex
  flex-row-reverse;
}

.detail__section > button {
  @apply hover:bg-primary
  hover:border-none
  hover:text-white
  transition;
}
