@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  p {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
      Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-weight: 100;
  }

  span {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
      Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-weight: 100;
  }

  h1 {
    @apply font-pt_serif
    font-[700]
    text-[48px]
    leading-[64px];
  }

  h2 {
    @apply font-pt_serif
    font-[700]
    text-[40px]
    leading-[54px];
  }

  h3 {
    @apply font-pt_serif
    font-[700]
    text-[32px]
    leading-[48px];
  }

  h4 {
    @apply font-pt_serif
    font-[700]
    text-[24px]
    leading-[32px];
  }

  h5 {
    @apply font-work_sans
    font-[600]
    text-[24px]
    leading-[32px];
  }

  h6 {
    @apply font-pt_serif
    font-[700]
    text-[20px]
    leading-[32px];
  }

  h7 {
    @apply font-work_sans
    font-[600]
    text-[20px]
    leading-[24px];
  }

  .button-text {
    @apply font-work_sans
    font-[400]
    text-[16px]
    leading-[24px];
  }

  .body-text-1--regular {
    @apply font-work_sans
    font-[400]
    text-[16px]
    leading-[24px];
  }

  .body-text-1--semi-bold {
    @apply font-work_sans
    font-[600]
    text-[16px]
    leading-[24px];
  }

  .body-text-2--regular {
    @apply font-work_sans
    font-[400]
    text-[14px]
    leading-[20px];
  }
}
