.app {
  @apply w-full
  h-screen
  bg-background
  text-black
  overflow-y-visible
  px-4;
}

.app__header {
  @apply m-auto
    w-full
    h-[80px]
    small-desktops:h-[128px]
    regular-desktops:w-[1140px];
}

.app__container {
  @apply h-fit
  m-auto
  w-full
  regular-desktops:w-[1140px];
}

.app__footer {
  @apply h-fit
  m-auto
  w-full
  tablets:h-[336px]
  regular-desktops:w-[1140px];
}
