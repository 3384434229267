.nav-bar {
  @apply h-full
  w-full
  flex
  items-center
  justify-between;
}

.nav-bar__navigation--desktop {
  @apply h-full
  gap-8
  flex
  items-center;
}

.nav-bar__navigation--desktop li {
  @apply cursor-pointer
    list-none	
    border-b
    border-background
    font-work_sans
    font-[600]
    text-[16px]
    leading-[24px];
}

.nav-bar__navigation--desktop li:hover {
  @apply border-primary;
}

.nav-bar__navigation--phone .navigation--phone__open-menu {
  @apply w-screen 
  h-screen 
  bg-background
  fixed 
  top-[80px] 
  left-0
  p-4
  flex
  flex-col
  gap-8
  z-50
  overflow-auto;
}

.nav-bar__navigation--phone .navigation--phone__open-menu li {
  @apply list-none
  font-work_sans
    font-[600]
    text-[16px]
    leading-[24px];
}

.nav-bar__navigation--phone .navigation--phone__open-menu hr {
  @apply text-gray;
}
