.successful-container__title {
  @apply font-montserrat mb-[16px] font-bold;
}

.successful-container__content {
  @apply mb-[16px];
}

.successful-container__buttons {
  @apply flex tablets:flex-row flex-col;
}

.successful-container__buttons > button {
  @apply py-[12px] px-[24px] font-bold rounded-[24px];
}
