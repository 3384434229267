.introduction-v2 {
  @apply w-full
    m-auto
    flex
    flex-col
    gap-12
    tablets:gap-0;
}

.introduction-v2__title {
  @apply text-center
  flex
  flex-col
  gap-3
  m-auto
  text-[32px]
  tablets:w-[478px]
  tablets:mb-12
  small-desktops:absolute
  small-desktops:top-0
  small-desktops:w-[461px]
  small-desktops:justify-center
  small-desktops:text-left
  small-desktops:text-[40px]
  small-desktops:h-[500px]
  small-desktops:leading-[54px]
  small-desktops:ml-[0px]
  large-desktops:h-[400px]
  large-desktops:w-[450px];
}

.introduction-v2__title > h3 {
  @apply font-montserrat 
  text-[32px] leading-[48px] font-[700]
  small-desktops:text-[32px]
  small-desktops:leading-[48px]
  regular-desktops:!text-[40px]
  regular-desktops:!leading-[48px]
  large-desktops:w-[500px];
}

.introduction-v2__title > h6 {
  @apply text-[#6B7181] text-[16px] 
  leading-[20px] font-[500] tablets:text-[20px]
  tablets:leading-[24px]
  regular-desktops:!text-[24px]
  regular-desktops:!leading-[32px]
  font-open_sans;
}

.introduction-v2__title > div > div > h5 {
  @apply text-[20px]
  leading-[24px]
  regular-desktops:text-[24px]
  regular-desktops:leading-[32px];
}

.introduction-v2__title > div > div > span {
  @apply text-[14px]
  leading-[20px]
  regular-desktops:text-[16px]
  regular-desktops:leading-[24px];
}

.introduction-v2__title > h3 {
  @apply font-montserrat text-[32px] leading-[48px] font-[700]
  small-desktops:text-[40px]
  small-desktops:leading-[54px]
  regular-desktops:text-[56px]
  regular-desktops:leading-[72px];
}

.introduction-v2__title > h6 {
  @apply text-[#6B7181] text-[16px] 
  leading-[20px] font-[500] tablets:text-[20px]
  tablets:leading-[24px]
  
  regular-desktops:text-[24px]
  regular-desktops:leading-[32px];
}

.introduction-v2__title .title__list-introduction-cards {
  @apply flex flex-col tablets:flex-row gap-4;
}

.title__list-introduction-cards > div > h5 {
  @apply text-[20px]
  leading-[24px]
  regular-desktops:text-[24px]
  regular-desktops:leading-[32px];
}

.title__list-introduction-cards > div > span {
  @apply text-[14px]
  leading-[20px]
  regular-desktops:text-[16px]
  regular-desktops:leading-[24px];
}

.scroll-container {
  @apply grid grid-flow-col gap-x-4 tablets:flex tablets:justify-between;
}

.scroll-container .scroll-container__card {
  @apply w-[90vw] tablets:w-[174px] small-desktops:w-[226px] large-desktops:w-[250px] shadow py-4 flex flex-col items-center px-4 gap-[13px];
}
