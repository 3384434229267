.tabs-component [role="tablist"] {
  @apply flex flex-wrap border-b-[1px] border-[#dee2e6]
    relative;
}

.tabs-component [role="tablist"] button {
  @apply border-[1px]
    border-transparent
    block
    px-[12px]
    py-[6px]
    cursor-pointer
    mx-[0]
    my-[1px]
    outline-none
    bg-[#fff];
}

.tabs-component [role="tablist"] button.active,
.tabs-component [role="tablist"] button:focus,
.tabs-component [role="tablist"] button:hover {
  @apply border-b-[1px]
    text-[#5C3BDE]
    border-b-[#5C3BDE];
}

.tabs-component.vertical {
  @apply flex flex-wrap items-start;
}

.tabs-component.vertical [role="tablist"] {
  @apply flex-col;
}

.tabs-component.vertical [role="tabpanel"] {
  @apply flex-1;
}

.tab-container {
  @apply bg-[#fff] w-full flex justify-center;
}

.tab-container .tab-container__labels {
  @apply w-full;
}

.tabpanel-fixed {
  @apply w-[326px]
    tablets:w-[756px]
    small-desktops:w-[1046px]
    regular-desktops:w-[1108px];
}

.tab-buttons {
  @apply w-full;
}
