.contact-company-v2 {
  @apply max-w-[343px] w-fit m-auto tablets:max-w-[552px];
}

.contact-company-v2 h4 {
  @apply font-montserrat 
    font-bold
    text-[24px]
    leading-[32px]
    regular-desktops:text-[40px]
    regular-desktops:leading-[54px];
}

.contact-company-v2 h5 {
  @apply font-montserrat 
    font-[500]
    text-[20px]
    text-[#6B7181]
    leading-[24px];
}
