.footer-v2 {
  @apply relative
  flex
  flex-col
  gap-4
  mt-[70px]
  tablets:gap-8;
}

.footer-v2__information {
  @apply flex
  flex-col
  gap-4
  small-desktops:flex-row
  small-desktops:gap-8;
}

.footer-v2_privacy {
  @apply flex 
  flex-wrap 
  gap-4
  w-[240px]
  tablets:w-full
  tablets:justify-end;
}

.footer-v2__icon:hover img {
  @apply -translate-y-14 duration-500;
}

.footer-v2__icon img {
  @apply duration-500;
}
