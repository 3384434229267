.ai-power-container {
  @apply font-montserrat justify-center flex;
}

.ai-power-container section {
  @apply flex flex-col
    large-desktops:w-[1440px]
    max-w-[1440px]
    items-center;
}

.ai-power-container section h2 {
  @apply text-[24px]
  tablets:text-[32px]
  tablets:leading-[48px]
  small-desktops:text-[40px];
}

.ai-power-container__values {
  @apply w-full
    hidden
    tablets:grid
    tablets:pt-[48px]
    tablets:grid-cols-3
    tablets:gap-y-8
    small-desktops:gap-y-12;
}
